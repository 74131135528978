<template>
  <div class="notification cursor-pointer">
    <div class="notif-2 flex photow-full mt-6 bg-white p-4 mb-2 rounded" :class="{'has-unread': (interlocuteurIsSender && !interlocuteur.lastmsg.seen)}">
      <div class="w-20 rounded-full">
        <p class="rounded-full none-image" v-if="!image"></p>
        <img v-if="image" :src="image" alt="profile picture" class="rounded-full h-18 w-18 profil-image ">
      </div>
      <div class="w-5/6 pl-4">
        <h5 class="font-bold pb-2 text-xl">
          {{ $t('newNotification') }}
        </h5>
        <p class="text-base font-light">
          <span><span class="sender">{{ interlocuteurDetails.fullname }}</span>&#32;{{ $t('haveNotification') }}</span>
        </p><br>
        <p class="campaign font-light py-3 text-center rounded pl-2 w-1/4" v-if="campaign.title">
          {{ campaign.title }}
        </p>
      </div>
      <div class="pt-6">
        <h5 class="font-light text-base">
          {{ getDateFormat(interlocuteur.lastmsg.sentAtDay + ' ' + interlocuteur.lastmsg.sentAt) }}
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
import { firebaseRef } from '../../../rema-function/firebaseRef'
import { firebaseDb } from '../../../main'
import firebaseStorage from '../../../rema-function/firebaseStorage'
import moment from 'moment'
import firebase from 'firebase'
export default {
  name: 'NotificationList',
  data () {
    return {
      image: '',
      interlocuteurDetails: {},
      campaign: {}
    }
  },
  props: [
    'interlocuteur',
    'guestUid'
  ],
  methods: {
    getDateFormat: function (date) {
      moment.locale('fr')
      let re = /dernier/gi
      if (moment(date).calendar().includes(`Aujourd’hui à`)) {
        return (moment(date).fromNow().includes(`il y a quelques secondes`) || moment(date).fromNow().includes(`il y a une minute`)) ? moment(date).fromNow().replace(`il y a quelques secondes`, `à l'instant`) : moment(date).fromNow()
      }
      return moment(date).calendar().replace(re, ``)
    }
  },
  firebase () {
    return {
      campaign: firebaseDb.ref(firebaseRef.mainsAdsFeeds).child(this.interlocuteur.campaignId)
      //  interlocuteurDetails: firebaseDb.ref(firebaseRef.users + this.guestUid + '/')
    }
  },
  async created () {
    let self = this
    await firebaseDb.ref(firebaseRef.users + this.guestUid + '/').once('value', function (snapshot) {
      self.interlocuteurDetails = snapshot.val()
    })
    firebaseStorage.getAvatar(this.guestUid).then(function (url) {
      if (url) {
        // this.$set(this.selectedUserStorage, 'iden', url)
        self.image = url
        //  alert(self.image)
      }
    }).catch(function (error) {
      if (error) console.log(error)
    })
  },
  computed: {
    interlocuteurIsSender: function () {
      // return (this.interlocuteur.lastmsg.from === this.currentUserUid)
      // alert(firebase.auth().currentUser.uid)
      return !(this.interlocuteur.lastmsg.from === firebase.auth().currentUser.uid)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../assets/style/sass/mixin";
  .notification {
    .sender {
      color: $Green-Rema;
    }
    .campaign {
      background-color: $light_gray3;
      min-width: 14rem;
    }
    /*  .notif-2:hover {
      background-color: $light_gray2;
    }*/
    .profil-image {
      height: 4rem;
      width: 4rem;
    }
    .none-image {
      height: 4rem;
      background-color: $Green-Rema;
      width: 4rem;
    }
    .has-unread {
      background-color: $light_gray2;
    }
  }
</style>
