<!--
 * @author : Kgeek
 * @since : 11/1/20, dim.
 -->

<template>
<div class="rema-gallery">
  <popup-base @clickOnBackground="emitBackgroundClick">
    <div class="flex justify-center">
      <div class="container rounded-lg crop-big-container  p-2 flex justify-center">
        <v-zoomer-gallery
          style="width: 500px; height: 500px;"
          :list="images"
          v-model="selIndex"
        ></v-zoomer-gallery>
      </div>
    </div>
  </popup-base>
</div>
</template>

<script>
import PopupBase from '@/components/helper/rema-components/popup-base'
export default {
  name: 'remaGallery',
  data () {
    return {
      selIndex: 0
    }
  },
  props: {
    images: []
  },
  methods: {
    emitBackgroundClick () {
      this.$emit('emitClosePopup')
    },
    generateImage () {
      let mesI = []
      for (let i = 0; i < this.images.length; i++) {
        mesI.push(this.images[i].url)
      }
      console.log(mesI)
      return mesI
    }
  },
  components: { PopupBase }
}
</script>

<style lang="scss" scoped>

</style>
