<template>
  <!-- v-click-outside="hideChat" -->
  <div
    class="histories"
    :class="{'is-not-all': !showAllConversation}"
  >
    <div
      class="container-for-control"
      @click="check"
    >
    </div>
    <page-header
      v-if="showAllConversation"
      :title="title"
      :subtitle="subtitle"
      :filter-op="false"
    />
    <div
      v-show="showAllConversation"
      class="page_content"
    >
      <NotificationList
        v-for="(item, key) in conversationsFiltered"
        :key="item+key"
        :interlocuteur="item"
        :guest-uid="item.guestUid"
        @click.native="showChatBox(item.key, item.guestUid, item.campaignId)"
      />
      <div
        v-if="conversationsFiltered.length === 0 && showAllConversation"
        class="none-campaigne "
      >
        <div class="icon-section text-center">
          <svg-icon
            name="ic-notification"
            class="w-16 h-16"
            color="#D8D8D8"
          />
        </div>
        <h3 class="text-center mt-3 text-xl font-light none-label">
          {{ $t('noneNotifications') }}
        </h3>
      </div>
    </div>
    <!--
    {{ allConversations }}
-->
    <div
      class="chat-section h-full"
    >
      <div
        v-show="!showAllConversation"
        v-click-outside="hideChat"
        class="flex w-full h-full "
      >
        <div class="w-1/2 h-full notification-list">
          <notification-list-in-chat
            v-for="(item, key) in conversationsFiltered"
            :key="item+key"
            :interlocuteur="item"
            :guest-uid="item.guestUid"
            @click.native="showChatBox(item.key, item.guestUid, item.campaignId)"
          />
        </div>
        <div
          class="w-full"
        >
          <chat-box
            :chatdata="chatData"
            @newMessage="newMessage"
            @emitBackToList="emitBackToList"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '../../components/helper/rema-components/page-header'
import NotificationList from '../../components/helper/rema-components/notification-list'
import { firebaseDb } from '../../main'
import { firebaseRef } from '../../rema-function/firebaseRef'
import firebase from 'firebase'
import ChatBox from '../../components/helper/rema-components/chat-box'
import NotificationListInChat from '../../components/helper/rema-components/notificationListInChat'
import ClickOutside from 'vue-click-outside'

export default {
  name: 'HomeRequests',
  components: { NotificationListInChat, ChatBox, PageHeader, NotificationList },
  directives: {
    ClickOutside
  },
  props: {
    msg: String
  },
  data () {
    return {
      title: this.$t('notification'),
      subtitle: this.$t('notificationSubtitle'),
      allConversations: [],
      showAllConversation: true,
      chatData: {
        uid: '',
        interlocuteurUid: '',
        campaignId: ''
      },
      isInAll: true
    }
  },
  firebase () {
    return {
      allConversations: firebaseDb.ref(firebaseRef.conversationRef + firebase.auth().currentUser.uid)
    }
  },
  computed: {
    conversationsFiltered: function () {
      let filterKey = ''
      // filterKey = this.filterKey.trim()
      let conversations = this.allConversations
      if (conversations.length !== 0) {
        if (firebase.auth().currentUser.uid) {
          var second
          let self = this
          if (filterKey && this.allConversations) {
            conversations = conversations.filter(function (row) {
              for (let i = 0; i < row.participants.length; i++) {
                if (row.participants[i].uid === self.user.uid) {
                  second = Math.abs(i - 1)
                  return String(row.participants[second].fullname).toLowerCase().indexOf(filterKey.toLowerCase()) > -1
                }
              }
            })
          }
          if (conversations != null && conversations.length > 0) {
            conversations.forEach(function (row) {
              for (let i = 0; i < row.participants.length; i++) {
                if (row.participants[i].uid === firebase.auth().currentUser.uid) {
                  // alert(row.participants[Math.abs(i - 1)].uid)
                  row.guestUid = row.participants[Math.abs(i - 1)].uid
                }
              }
            })
          }
        }
        if (conversations != null && conversations.length > 0) {
          return conversations.sort(function (a, b) {
            return new Date(b.lastmsg.timestampString).getTime() - new Date(a.lastmsg.timestampString).getTime()
          })
        } else {
          return new Date().getTime()
        }
      } else {
        return new Date().getTime()
      }
    }
  },
  async created () {
    // alert(firebase.auth().currentUser.uid)
  },
  methods: {
    check () {
      // alert('me')
      if (!this.showAllConversation) {
        // this.$router.go()
        // console.log('egrhn')
        this.showAllConversation = true
      }
    },
    showChatBox (uid, interlocuteur, campaignId) {
      firebaseDb.ref(firebaseRef.conversationRef + firebase.auth().currentUser.uid).child(uid).child('lastmsg').child('seen').set(true)
      this.chatData.uid = uid
      this.chatData.interlocuteurUid = interlocuteur
      this.chatData.campaignId = campaignId
      this.showAllConversation = false
    },
    emitBackToList (answer) {
      this.chatData.uid = ''
      this.chatData.interlocuteurUid = ''
      this.chatData.campaignId = ''
      this.showAllConversation = answer
    },
    hideChat () {
      if (!this.showAllConversation) {
        if (this.isInAll) {
          this.isInAll = false
        } else {
          this.isInAll = true
          this.showAllConversation = true
        }
      }
    },
    newMessage () {
      let vm = this
      // alert('gi')
      firebaseDb.ref(firebaseRef.conversationRef + firebase.auth().currentUser.uid).once('value', function (snapchot) {
        console.log(snapchot)
        vm.allConversations = []
        vm.allConversations = snapchot.val()
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .notification-list {
    margin-top: -4% !important;
    margin-left: -2.7% !important;
    background-color: white;
  }
  .is-not-all {
    background-color: white;
  }
  .none-label {
    color: #D8D8D8;
  }
  .none-campaigne {
    margin-top: 10%;
  }
  .container-for-control {
    position: absolute;
    background-color: transparent;
    height: 5rem;
    left: 0;
    top: 21rem;
    width: 21rem;
  }
</style>
