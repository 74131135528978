<template>
  <div class="chat-are-there -mt-10  fixed w-full h-full">
    <my-custom-loader v-if="showload"></my-custom-loader>

    <div
      v-if="showOption"
      class=" mt-0 action-list absolute"
    >
      <div
        v-if="showDocOption"
        class="block-one flex justify-center items-center  cursor-pointer  h-12 w-12 bg-green rounded-full"
        @click.stop="showDocSelect"
      >
        <svg-icon
          name="ic_pic_doc"
          color="#ffffff"
          class="w-8 h-8"
        />
      </div>
      <div
        v-if="showImageOption"
        class="block-one h-12 flex justify-center cursor-pointer items-center mt-3 w-12 bg-green rounded-full"
        @click.stop="showSelect"
      >
        <svg-icon
          name="ic_pic_image"
          color="#ffffff"
          class="w-8 h-8"
        />
      </div>
    </div>
    <div class="header bg-white shadow-sm border-b border-l p-2  mr-10">
      <div class="flex">
        <div
          class="back-arrow invisible cursor-pointer"
          @click="backToList"
        >
          <svg-icon
            name="ic-back"
            :color="remaGreen"
            class="w-6 h-6 mt-3"
          />
        </div>
        <div class="flex-one image-section ml-3">
          <p
            v-if="!image"
            class="rounded-full none-image"
          />
          <div class="rounded-full image-profil">
            <img
              v-if="image"
              :src="image"
              class="rounded-full h-full w-full"
            >
          </div>
        </div>
        <div class="flex-one w-full   details-section ">
          <h3 class="text-lg ml-2 font-normal">
            {{ interlocuteurDetails.fullname }}
          </h3>
          <h3 class="text-sm ml-2 font-light">
            {{ interlocuteurDetails.remaUserInfos.statutPro }}
          </h3>
        </div>
        <div
          class="flex-one w-1/3 campaign-name text-right mr-3"
        >
          {{ campaign.title }}
        </div>
      </div>
    </div>
    <div
      :id="'chatBottom' + chatdata.uid"
      v-chat-scroll
      class="conversation-section w-full border-l  h-full p-2"
      @click="showSelectEmoji = false"
    >
      <div
        id="message-container"
        ref="toolbarChat"
        v-chat-scroll
        class="messages"
      >
        <div
          v-for="(msg, key) in chats"
          :key="msg+key"
          class="messages"
        >
          <div
            v-if="!isSelf(msg.from)"
            class="flex mb-2"
            @click.prevent
          >
            <div
              class="rounded py-2 px-3 message-content"
              style="background-color: #F2F2F2"
            >
              <div
                v-if="typeof msg.images != 'undefined'"
                class="imageBlock rounded-lg cursor-pointer mb-2"
              >
                <img
                  :src="msg.images[0].url"
                  @click="(msg.images)"
                >
                <h class="dd p-1 bg-black rounded-lg text-white text-sm">
                  {{ msg.images.length }} images
                </h>
              </div>
              <div v-if="typeof msg.audios != 'undefined'">
                <audio class="my_audio" controls preload="none">
                  <source :src="msg.audios.url" type="audio/wav">
<!--
                  <source :src="msg.audios.url" type="audio/wav">
-->
                </audio>
              </div>
              <div
                v-if="typeof msg.doc != 'undefined'"
                class="pdf-message flex border w-full items-center mt-2 mb-2 rounded-lg p-2 border-black"
              >
                <div class="flex-one w-1/3 h-8 ">
                  <svg-icon
                    name="ic_pdf_download"
                    class="h-8 w-8"
                    original
                  />
                </div>
                <div class="flex-one w-1/3 ml-2">
                  {{ msg.doc.name }}
                </div>
                <div class="flex-one w-1/3 ml-4 flex  justify-end">
                  <div
                    class="h-8 w-8 rounded-full flex justify-center items-center border border-black cursor-pointer"
                    @click="downloadPdf(msg.doc.url, msg.doc.name)"
                  >
                    <svg-icon
                      name="ic-download-arrow"
                      class="h-4 w-4"
                    />
                  </div>
                </div>
              </div>

              <p
                class="text-sm  mt-1"
                v-html="formatMessages(msg.content)"
              />

              <!--
              <p  >{{ msg.doc }}</p>
-->

              <p class="text-right text-xs text-grey-dark mt-1" v-if="typeof msg.dateTimeTimestamp == 'undefined'">
                {{ formatTime(msg.sentAtDay + ' ' + msg.sentAt) }}
              </p>
              <p class="text-right text-xs text-grey-dark mt-1" v-if="typeof msg.dateTimeTimestamp != 'undefined'">
                {{ formatTime(msg.dateTimeTimestamp) }}
              </p>
            </div>
          </div>
          <div
            v-if="isSelf(msg.from)"
            class="flex justify-end mb-2"
            @click.prevent
          >
            <div
              class="rounded py-2 px-3 message-content"
              style="background-color: #E2F7CB"
            >
              <div
                v-if="typeof msg.images != 'undefined'"
                class="imageBlock rounded-lg cursor-pointer mb-8"
              >
                <img
                  :src="msg.images[0].url"
                  @click="showImageInGalery(msg.images)"
                >
                <h class="dd p-1 bg-black rounded-lg text-white text-sm">
                  {{ msg.images.length }} images
                </h>
              </div>
              <div v-if="typeof msg.audios != 'undefined'">
                <audio class="my_audio" controls preload="none">
                  <source :src="msg.audios.url" type="audio/wav">
<!--
                  <source :src="msg.audios.url" type="audio/wav">
-->
                </audio>
              </div>
              <div
                v-if="typeof msg.doc != 'undefined'"
                @click="openMyZFile(msg.doc.url)"
                class="pdf-message cursor-pointer flex border w-full items-center mt-2 mb-2 rounded-lg p-2 border-black"
              >
                <div class="flex-one w-1/3 h-8 ">
                  <svg-icon
                    name="ic_pdf_download"
                    class="h-8 w-8"
                    original
                  />
                </div>
                <div class="flex-one w-fu ml-2">
                  {{ msg.doc.name }}
                </div>
              </div>
              <p
                class="text-sm  mt-1"
                v-html="formatMessages(msg.content)"
              />
              <p class="text-right text-xs text-grey-dark mt-1" v-if="typeof msg.dateTimeTimestamp == 'undefined'">
                {{ formatTime(msg.sentAtDay + ' ' + msg.sentAt) }}
              </p>
              <p class="text-right text-xs text-grey-dark mt-1" v-if="typeof msg.dateTimeTimestamp != 'undefined'">
                {{ formatTime(msg.dateTimeTimestamp) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="selectedImageList w-full" v-if="selectedImagesDataUrl.length > 0">
      <div class="flex justify-start items-center h-22 pt-2 pb-2">
        <div v-for="(item, key) in selectedImagesDataUrl" :key="item + key">
          <div class="h-16 w-16 rounded-lg ml-2" >
            <div>
              <img :src="item" class="w-16 h-16" style="object-fit: contain"/>
            </div>
            <div class=" clear-icon flex ml-4  -mt-12 " @click.stop="deleteImage(key)">
              <div class="h-8 w-8 flex justify-center items-center rounded-full bg-white shadow cursor-pointer">
                <svg-icon
                  name="img_delete"
                  class="w-3 h-3"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-section border-l bg-white shadow w-full p-2">
      <div
        v-if="showSelectEmoji"
        class="emogi-select"
      >
        <picker
          :show-preview="false"
          :show-search="false"
          @select="addEmoji"
        />
      </div>
      <div class="flex w-full rounded mr-4">
        <div
          class="flex-one cursor-pointer emogi-sicon mt-2"
          @click="showSelectEmoji = !showSelectEmoji"
        >
          <H3>
            <svg-icon
              name="ic-smiley"
              color="#FFB300"
              class="w-6 h-6 mt-3"
            />
          </H3>
        </div>
        <div
          class="flex-one cursor-pointer emogi-sicon mt-2 ml-2 mr-2"
          @click="showOptionList"
        >
          <H3>
            <svg-icon
              name="ic_attach"
              color="#16B853"
              class="w-6 h-6 mt-3"
            />
          </H3>
        </div>
        <div class="flex-one w-full input-flex h-full mr-2 pt-1">
          <textarea
            v-model.trim="message"
            class="rounded-full w-full h-full text-fff p-3"
            @keyup="changeList"
            @focus="showSelectEmoji = false"
          />
        </div>
        <div
          v-if="showSend && !showRecordLister"
          class="flex-one send-button mr-4 "
        >
          <div
            class="buton h-12 cursor-pointer text-center w-12 rounded-full  flex items-center justify-center bg-green"
            @click="pushMsg"
          >
            <H3>
              <svg-icon
                name="ic-back"
                color="#ffffff"
                class="w-6 h-6  mt-1 mr-1"
              />
            </H3>
          </div>
        </div>
        <div
          v-if="!showSend && !showRecordLister"
          class="flex-one send-button mr-4 "
        >
          <div
            @click="startRecorc"
            class="buton-audio h-12 cursor-pointer text-center w-12 rounded-full "
          >
            <H3>
              <svg-icon
                name="ic_audio"
                color="#16B853"
                class="w-6 h-6 mt-3"
              />
            </H3>
          </div>
        </div>
        <div v-if="showRecordLister" class="flex-one items-center flex w-1/4">
          <div class="flex-first">
            <div class="w-8 h-8 rounded-full  cursor-pointer flex items-center justify-center border bg-red  border-red">
             <h3 class="text-white font-bold text-xl">X</h3>
            </div>
          </div>
          <div class="flex-first ml-2 mr-2 w-full text-center"> 00 : {{ timeCounter }}</div>
          <div class="flex-first">
            <div class="w-8 h-8 rounded-full rounded-send-audio cursor-pointer flex items-center justify-center border bg-green border-green">
            <svg-icon
              name="ic-back"
              color="white"
              class="w-4 h-4"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <rema-gallery
      v-if="showGaleri"
      :images="selectedMessagesImages"
      @emitClosePopup="closeGallery"
    />
  </div>
</template>

<script>
import { Picker } from 'emoji-mart-vue'
import { firebaseDb } from '../../../main'
import { firebaseRef } from '../../../rema-function/firebaseRef'
import firebaseStorage, { chatFilesStorageRef } from '../../../rema-function/firebaseStorage'
import firebase from 'firebase'
import moment from 'moment'
import RemaGallery from '@/components/helper/rema-components/rema-gallery'
import MyCustomLoader from '@/components/helper/rema-components/my-custom-loader'
import AudioRecorders from 'audio-recorders'

export default {
  name: 'ChatBox',
  components: {
    MyCustomLoader,
    RemaGallery,
    Picker
  },
  props: {
    chatdata: {
      type: Object,
      required: true,
      default: function () {
        return {
          campaignId: '',
          interlocuteurUid: '',
          uid: ''
        }
      }
    }
  },
  data () {
    return {
      showSend: true,
      showOption: false,
      showImageOption: false,
      showDocOption: false,
      image: '',
      remaGreen: '#16B853',
      message: '',
      showSelectEmoji: false,
      campaign: {},
      interlocuteurDetails: {},
      chats: [],
      showGaleri: false,
      selectedMessagesImages: [],
      pdfDataUrl: [],
      pdfData: {
        downloadName: '',
        filePath: '',
        name: '',
        url: ''
      },
      showload: false,
      selectedImagesDataUrl: [],
      selectedImagesPath: [],
      realImageList: [],
      showRecordLister: false,
      timeCounter: 0
    }
  },
  computed: {
    computedChatData () {
      return this.goToEnd()
    }
  },
  created () {
    // alert(new Date().getTime())
    let self = this
    firebaseStorage.getAvatar(this.chatdata.interlocuteurUid).then(function (url) {
      if (url) {
        // this.$set(this.selectedUserStorage, 'iden', url)
        self.image = url
        //  alert(self.image)
      }
    }).catch(function (error) {
      if (error) console.log(error)
    })
    firebaseDb.ref(firebaseRef.users).child(this.chatdata.interlocuteurUid).once('value', function (snapchot) {
      self.interlocuteurDetails = snapchot.val()
    })
    firebaseDb.ref(firebaseRef.chatRef).child(this.chatdata.uid).once('value', function (snapchot) {
      self.chats = snapchot.val()
    })
    this.pollData()
  },
  methods: {
    startRecorc () {
      this.showRecordLister = true
      navigator.mediaDevices.getUserMedia({ audio: true }, (stream) => {
        const config = {
          exportAudio: 'wav'
        }

        this.recorder = new AudioRecorders(stream, config)

        this.recorder.onStreamProcessor = (buffer) => {}
        this.recorder.onReceiveAudioBlob = (blobs) => {}

        this.recorder.startRecord()
      })
      this.buildTimeCouter()
    },
    buildTimeCouter () {
      let vm = this
      let downloadTimer = setInterval(() => {
        if (vm.timeCounter >= 60) {
          clearInterval(downloadTimer)
        } else {
          vm.timeCounter++
        }
      }, 1000)
    },
    deleteImage (position) {
      this.selectedImagesDataUrl.splice(position, 1)
      this.selectedImagesPath.splice(position, 1)
      // this.$emit('selectedImages', this.images)
    },
    openMyZFile (url) {
      window.open(url, '_blank')
    },
    createImage (file) {
      let reader = new FileReader()
      let vm = this
      reader.onload = (e) => {
        vm.selectedImagesDataUrl.push(e.target.result)
      }
      return reader.readAsDataURL(file)
    },
    createFile (file) {
      this.showload = true
      let reader = new FileReader()
      let vm = this
      reader.onload = (e) => {
        vm.pdfDataUrl.push(e.target.result)
        var currentUser = firebase.auth().currentUser.uid.toString()
        // alert(currentUser)
        console.log(vm.pdfDataUrl)
        chatFilesStorageRef.child(currentUser).child(vm.pdfData.downloadName)
          .putString(vm.pdfDataUrl[0], 'data_url')
          .then((snapshot) => {
            snapshot.ref.getDownloadURL().then((downloadUrl) => {
              vm.pdfData.url = downloadUrl
              console.log(vm.pdfData)
              // alert('terminer')
              vm.pushDocMsg()
            })
              .catch((err) => {
                alert(err)
              })
          })
          .catch((err) => {
            alert(JSON.stringify(err))
          })
      }
      return reader.readAsDataURL(file)
    },
    downloadPdf (url, name) {
      let link = document.createElement('a')
      // If you don't know the name or want to use
      // the webserver default set name = ''
      link.setAttribute('download', 'dd.pdf')
      link.href = url
      document.body.appendChild(link)
      link.click()
      link.remove()
    },
    showImageInGalery (image) {
      // console.log('febgrfhmg')
      // alert('fdgsh')
      let mesI = []
      for (let i = 0; i < image.length; i++) {
        mesI.push(image[i].url)
      }
      console.log(mesI)
      this.selectedMessagesImages = mesI
      this.showGaleri = true
    },
    closeGallery () {
      this.showGaleri = false
    },
    showDocSelect () {
      let vm = this
      // alert('me')
      this.showOption = false
      let input = document.createElement('input')
      input.type = 'file'
      input.setAttribute('multiple', 'multiple')
      input.setAttribute('accept', '.pdf,.pptx')
      input.onchange = e => {
        let files = e.target.files
        let file = e.target.files.length
        for (let i = 0; i < file; i++) {
          this.createFile(files[i])
          // alert(files[i])
          console.log(files[i])
          vm.pdfData.filePath = files[i].url
          vm.pdfData.name = files[i].name
          vm.pdfData.filePath = files[i].name
          if (vm.pdfData.name.includes('pdf')) {
            vm.pdfData.downloadName = moment().format('YYYY-MM-DD HH:mm:ss.SSS').toString() + '.pdf'
          } else {
            vm.pdfData.downloadName = moment().format('YYYY-MM-DD HH:mm:ss.SSS').toString() + '.pptx'
          }
          console.log(vm.pdfData)
          // this.images.imagesReal.push(files[i])
          // this.$emit('selectedImages', this.images)
        }
      }
      input.setAttribute('capture', 'camera')
      input.click()
    },
    showSelect () {
      this.showOption = false
      let input = document.createElement('input')
      input.type = 'file'
      input.setAttribute('multiple', 'multiple')
      input.setAttribute('accept', 'image/*')
      input.onchange = e => {
        let files = e.target.files
        let file = e.target.files.length
        for (let i = 0; i < file; i++) {
          this.createImage(files[i])
          this.selectedImagesPath.push(files[i])
          this.$emit('selectedImages', this.images)
        }
      }
      input.setAttribute('capture', 'camera')
      input.click()
    },
    changeList () {
      if (this.message.length > 0) {
        this.showSend = true
      } else {
        this.showSend = false
      }
    },
    showOptionList () {
      let vm = this
      if (this.showOption) {
        this.showOption = false
      } else {
        this.showOption = true
        setTimeout(() => {
          vm.showDocOption = true
        }, 200)
        setTimeout(() => {
          vm.showImageOption = true
        }, 300)
      }
    },
    pollData () {
      let self = this
      setInterval(() => {
        firebaseDb.ref(firebaseRef.chatRef).child(this.chatdata.uid).once('value', function (snapchot) {
          self.chats = snapchot.val()
          // console.log(snapchot.val())
          self.$emit('newMessage', true)
        })
      }, 3000)
    },
    addEmoji (emoji) {
      console.log(emoji)
      this.message += emoji.native
    },
    backToList () {
      this.$emit('emitBackToList', true)
    },
    formatMessages (msg) {
      /*eslint-disable */
      let urlRegex = /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi
      return msg.replace(urlRegex, function (url) {
        return '<a href="' + url + '" target="_blank">' + url + '</a>'
      })
    },
    isSelf: function (from) {
      // return (this.interlocuteur.lastmsg.from === this.currentUserUid)
      return (from === firebase.auth().currentUser.uid)
    },
    formatTime (realTime) {
      moment.locale('fr')
      let re = /dernier/gi
      if (moment(realTime).calendar().includes(`Aujourd’hui à`)) {
        return (moment(realTime).fromNow().includes(`il y a quelques secondes`) || moment(realTime).fromNow().includes(`il y a une minute`)) ? moment(realTime).fromNow().replace(`il y a quelques secondes`, `à l'instant`) : moment(realTime).fromNow()
      }
      return moment(realTime).calendar().replace(re, ``)
    },
     pushMsg () {
      if (this.selectedImagesPath.length > 0) {
        this.sendMultiplesImage()
        let vm = this
       setTimeout(() => {
         let sendMessage = this.message.trim()
         this.message = ''
         /* this.chat.push({
           content: this.newMsgText.trim(),
           from: 'ere855',
           sentAt: moment().locale('fr').format('LT')
         }) */
         let finMsg = {
           content: sendMessage,
           from: firebase.auth().currentUser.uid,
           incomming: false,
           seen: false,
           sent: false,
           sentAt: moment().locale('fr').format('LT'),
           sentAtDay: moment().locale('fr').format('YYYY-MM-DD'),
           timestampString: moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
           to: this.chatdata.interlocuteurUid,
           isImage: true,
           images: this.realImageList,
           dateTimeTimestamp: new Date().getTime(),
         }
         firebaseDb.ref('messages/' + this.chatdata.uid).push(finMsg)
         firebaseDb.ref('userChatsKeys/' + firebase.auth().currentUser.uid + '/' + this.chatdata.uid + '/lastmsg').set(finMsg)
         firebaseDb.ref('userChatsKeys/' + this.chatdata.interlocuteurUid + '/' + this.chatdata.uid + '/lastmsg').set(finMsg)
         firebaseDb.ref(firebaseRef.chatRef).child(this.chatdata.uid).once('value', function (snapchot) {
           vm.chats = snapchot.val()
         })
         vm.$emit('newMessage', true)
         // Reoad notif
         // this.chats.push(finMsg)

         // for notifications
         let notifDest = {
           action: 'OPEN_CHAT',
           body: 'Vous avez un nouveau message',
           metadatas: {
             AUTHOR_UID: firebase.auth().currentUser.uid,
             openchat: firebase.auth().currentUser.uid
           },
           timestampString: moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
           title: 'Nouveaux Messages',
           type: 'CHAT'
         }
         firebaseDb.ref('notifRef/' + this.chatdata.interlocuteurUid).push(notifDest)
         //  this.message.clean()
         this.showload = false;
         this.realImageList = [];
         this.selectedImagesPath = [];
         this.selectedImagesDataUrl = [];
       }, 20000 )

      } else if (this.message.trim() !== '' ) {
        let vm = this
        let sendMessage = this.message.trim()
        this.message = ''
        /* this.chat.push({
          content: this.newMsgText.trim(),
          from: 'ere855',
          sentAt: moment().locale('fr').format('LT')
        }) */
        let finMsg = {
          content: sendMessage,
          from: firebase.auth().currentUser.uid,
          incomming: false,
          seen: false,
          sent: false,
          sentAt: moment().locale('fr').format('LT'),
          sentAtDay: moment().locale('fr').format('YYYY-MM-DD'),
          timestampString: moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
          to: this.chatdata.interlocuteurUid,
          dateTimeTimestamp: new Date().getTime(),
        }
        firebaseDb.ref('messages/' + this.chatdata.uid).push(finMsg)
        firebaseDb.ref('userChatsKeys/' + firebase.auth().currentUser.uid + '/' + this.chatdata.uid + '/lastmsg').set(finMsg)
        firebaseDb.ref('userChatsKeys/' + this.chatdata.interlocuteurUid + '/' + this.chatdata.uid + '/lastmsg').set(finMsg)
        firebaseDb.ref(firebaseRef.chatRef).child(this.chatdata.uid).once('value', function (snapchot) {
          vm.chats = snapchot.val()
        })
        vm.$emit('newMessage', true)
        // Reoad notif
        // this.chats.push(finMsg)

        // for notifications
        let notifDest = {
          action: 'OPEN_CHAT',
          body: "Vous avez un nouveau message",
          metadatas: {
            AUTHOR_UID: firebase.auth().currentUser.uid,
            openchat: firebase.auth().currentUser.uid
          },
          timestampString: moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
          title: 'Nouveaux Messages',
          type: 'CHAT'
        }
        firebaseDb.ref('notifRef/' + this.chatdata.interlocuteurUid).push(notifDest)
        //  this.message.clean()
      }
    },
    pushDocMsg () {
      if (true) {
        let vm = this
        let sendMessage = this.message.trim()
        this.message = ''
        /* this.chat.push({
          content: this.newMsgText.trim(),
          from: 'ere855',
          sentAt: moment().locale('fr').format('LT')
        }) */
        let finMsg = {
          content: sendMessage,
          from: firebase.auth().currentUser.uid,
          incomming: false,
          seen: false,
          sent: false,
          sentAt: moment().locale('fr').format('LT'),
          sentAtDay: moment().locale('fr').format('YYYY-MM-DD'),
          timestampString: moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
          to: this.chatdata.interlocuteurUid,
          doc: this.pdfData,
          isDoc: true,
          dateTimeTimestamp: new Date().getTime(),

        }
        firebaseDb.ref('messages/' + this.chatdata.uid).push(finMsg)
        firebaseDb.ref('userChatsKeys/' + firebase.auth().currentUser.uid + '/' + this.chatdata.uid + '/lastmsg').set(finMsg)
        firebaseDb.ref('userChatsKeys/' + this.chatdata.interlocuteurUid + '/' + this.chatdata.uid + '/lastmsg').set(finMsg)
        firebaseDb.ref(firebaseRef.chatRef).child(this.chatdata.uid).once('value', function (snapchot) {
          vm.chats = snapchot.val()
        })
        vm.$emit('newMessage', true)
        // Reoad notif
        // this.chats.push(finMsg)

        // for notifications
        let notifDest = {
          action: 'OPEN_CHAT',
          body: 'Vous avez un nouveau message',
          metadatas: {
            AUTHOR_UID: firebase.auth().currentUser.uid,
            openchat: firebase.auth().currentUser.uid
          },
          timestampString: moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
          title: 'Nouveaux Messages',
          type: 'CHAT'
        }
        firebaseDb.ref('notifRef/' + this.chatdata.interlocuteurUid).push(notifDest)
        //  this.message.clean()
        this.showload = false
      }
    },
    typeOfCampaign () {
      if (typeof this.chatdata.campaignId !== 'undefined') {
        return true
      } else {
        return false
      }
    },
    goToEnd () {
      // alert('hi')
      let container = this.$el.querySelector("#chatBottom" + this.chatdata.uid);
      container.scrollTop = container.scrollHeight;
    },
    sendMultiplesImage () {
      this.showload = true
      // alert(this.selectedImagesPath.length)
      for (let i = 0; i < this.selectedImagesPath.length;  i++) {
          this.uploadImageAsPromise(this.selectedImagesPath[i])
      }
    },
    uploadImageAsPromise (imageFile) {
      let vm = this
      let image = {
        downloadName: imageFile.name,
        filePath: imageFile.name,
        name: imageFile.name,
        url: ''
      }
      var currentUser = firebase.auth().currentUser.uid.toString()
      // alert(currentUser)
      return new Promise(function (resolve, reject) {
      var storageRef = chatFilesStorageRef.child(currentUser).child(new Date().getTime().toString());

    //Upload file
    var task = storageRef.put(imageFile);

    //Update progress bar
    task.on('state_changed',
      function progress(snapshot){
        var percentage = snapshot.bytesTransferred / snapshot.totalBytes * 100;
        // alert(percentage)
       // uploader.value = percentage;
      },
      function error(err){
      // alert(err)
      },
      function complete(){
        task.snapshot.ref.getDownloadURL().then((url) =>{
          image.url = url
          vm.realImageList.push(image)
          //alert('termubr')
         // alert(url)
        })
        .catch((err) => {
          alert(err);
        })
      }
    );
  })
}
},
  firebase () {
    return {
      campaign: firebaseDb.ref(firebaseRef.mainsAdsFeeds).child(this.chatdata.campaignId),
      interlocuteurDetails: firebaseDb.ref(firebaseRef.users).child(this.chatdata.interlocuteurUid),
      chats: firebaseDb.ref(firebaseRef.chatRef).child(this.chatdata.uid)
    }
  },
  watch:  {
    'chatdata.campaignId': function (newValue) {
      let vm = this
      firebaseDb.ref(firebaseRef.mainsAdsFeeds).child(newValue)
        .once('value', function (snapshot) {
          vm.campaign = snapshot.val()
        //  alert(snapshot.val())
        })
      vm.goToEnd()
    },
    'chatdata.interlocuteurUid': function (newValue) {
       let vm = this
        firebaseDb.ref(firebaseRef.users).child(newValue)
          .once('value', function (snapchot) {
           //  alert('hi')
            console.log(snapchot.val())
            vm.interlocuteurDetails = snapchot.val()
          })
      firebaseStorage.getAvatar(newValue).then(function (url) {
        if (url) {
          // this.$set(this.selectedUserStorage, 'iden', url)
          vm.image = url
          vm.goToEnd()
          //  alert(self.image)
        }
      }).catch(function (error) {
        if (error) console.log(error)
      })
    },
    'chatdata.uid': function (newValue) {
      let vm = this
      firebaseDb.ref(firebaseRef.chatRef).child(newValue)
        .once('value', function (snapchot) {
        //  alert('hi')
          console.log(snapchot.val())
          vm.chats = snapchot.val()
          vm.goToEnd()

        })
    }
    }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
  .header {
    width: 60%;
    padding-right: 8%;
  }
.input-flex {
  width: 99%;
}
.profil-image {
  height: 3rem;
  width: 3rem;
}
.none-image {
  height: 3rem;
  background-color: $Green-Rema;
  width: 3rem;
}
  .details-section {
    margin-top: .4rem;
  }
  .campaign-name {
    margin-top: .8rem;
  }
.image-section {
  width: 8%;
}
  .bottom-section {
    // background-color: $Rema-Content-Background-b;
    position: fixed;
    bottom: 0;
    width: 53%;
  }
  .selectedImageList {
    background-color: white;
    position: fixed;
    bottom: 10%;
  }
textarea {
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none; /*remove the resize handle on the bottom right*/
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: .2; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  opacity: .2; /* Firefox */
}

::-ms-input-placeholder { /* Microsoft Edge */
  opacity: .2; /* Firefox */
}
  .emogi {
    width: 8%;
  }
  .send-button{
    transform: rotate(180deg);
    margin-top: .5rem;
    div {
      margin-top: 1.2rem;
    }
  }
  .conversation-section {
    overflow-y: scroll;
    width: 54%;
    padding-bottom: 14%;
    padding-right: 3%;
    padding-left: 3%;
    background-color: $Rema-Content-Background;
    height: calc(100vh - 170px);
  }
  .emogi-text {
    background-color: transparent !important;
  }
  .emogi-text:focus {
    outline: -webkit-focus-ring-color auto 0px;
  }
  .emogi-select {
    position: absolute;
    margin-top: -31rem;
  }
  .emogi-sicon {
    width: 3%;
  }
  .back-arrow {
    display: none;
  }
  .text-fff {
    height: 3.3rem !important;
    background-color: $Rema-Content-Background;
  }
  .message-content{
    max-width: 50% !important;
  }
  .imageBlock  {
    height: 15rem;
    width: 15rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    // background-color: red;
  }
  .message-content {
    max-width: 16.5rem;
  }
  .image-profil {
    height: 3rem;
    width: 3rem;
  }
  .action-list {
    position: absolute;
    height: 7rem;
    bottom: 18%;
    left: 1.5%;
    z-index: 9999;
  }
  .buton-audio {
    //background-color: black;
    transform: rotate(.5turn);
  }
  .dd {
    opacity: .8;
  }
  .clear-icon {
    position: absolute;
  }
  .rounded-send-audio {
    transform: rotate(.5turn);
  }
  .my_audio .play  {
    border: 1rem solid grey;
    outline: none !important;

  }
  audio:focus {
    outline: none !important;

  }
</style>
